// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environment: 'DEV',
  firebaseConfig: {
    apiKey: 'AIzaSyC7hTE6sV9_2jOyWS_4TGhcdBA_60B6lmo',
    authDomain: 'sharescapeio-dev.firebaseapp.com',
    databaseURL: 'https://sharescapeio-dev.firebaseio.com',
    projectId: 'sharescapeio-dev',
    storageBucket: 'sharescapeio-dev.appspot.com',
    messagingSenderId: '57611256124',
    appId: '1:57611256124:web:79ea694d5cb1f80ac39e39',
    measurementId: 'G-VVE3CE3PQJ',
  },
  hasura: {
    https: 'https://dev-api.sharescape.io/v1/graphql',
    wss: 'wss://dev-api.sharescape.io/v1/graphql',
  },
  apiUrl: 'https://dev-api.sharescape.io',
  logToConsole: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
